export type AnyRecord = Record<string, any>;
export type AnyFunction = (...args: any[]) => any;

function deMinimizedHotjarInitScript(
  id,
  window,
  document,
  urlPrefix = 'https://static.hotjar.com/c/hotjar-',
  urlSuffix = '.js?sv='
) {
  window.hj =
    window.hj ||
    function () {
      (window.hj.q = window.hj.q || []).push(arguments);
    };
  window._hjSettings = { hjid: id, hjsv: 6 };
  const headElement = document.getElementsByTagName('head')[0];
  const scriptElement = document.createElement('script');
  scriptElement.async = 1;
  scriptElement.src = urlPrefix + window._hjSettings.hjid + urlSuffix + window._hjSettings.hjsv;
  headElement.appendChild(scriptElement);
}

export interface IHotjar {
  event: (eventName: string) => void;
  identify: (userId: string, userAttributes?: AnyRecord) => void;
  unidentify: () => void;
}

export function initializeHotjar(id: number, window: Window): IHotjar {
  let hjInstance: AnyFunction;
  if (id) {
    deMinimizedHotjarInitScript(id, window, window.document);
    hjInstance = window['hj'];
  } else {
    console.error('Hotjar ID is not provided. Hotjar will not be initialized.');
    hjInstance = () => {};
  }

  return {
    event: event => {
      hjInstance('event', event);
    },
    identify: (userId, userAttributes) => {
      hjInstance('identify', userId, userAttributes);
    },
    unidentify: () => {
      hjInstance('identify', null);
    },
  };
}
