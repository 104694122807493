import './PostPreview.css';

import React, { useState } from 'react';

const LiIcon: React.FC<any> = ({ className, ...passProps }) => {
  return <li {...passProps} className={`${className || ''} LiIcon`} />;
};

// Pure guess :)
const VISUAL_LINE_LENGTH = 72;

export const PostPreview: React.FC<{
  className?: string;
  content: string;
  style?: React.CSSProperties;
  additionalInfo?: object;
}> = ({ className, content, style, additionalInfo }) => {
  const [expanded, setExpanded] = useState(false);

  const htmlContent = [];
  let estimatedHeight = 0;
  let lastEmpty = true;
  let fiveLines = false;
  let showSeeMore = true;
  const lines = (content || '').split('\n');
  let line: string;
  let isEmpty: boolean;
  for (let index = 0; index < lines.length; index++) {
    line = lines[index];

    isEmpty = line.trim() === '';
    if (isEmpty) {
      if (lastEmpty) {
        continue;
      }
      lastEmpty = true;
      estimatedHeight += 1;
    } else {
      lastEmpty = false;
      const lineCount = Math.ceil(line.trim().length / VISUAL_LINE_LENGTH);
      estimatedHeight += lineCount;
    }

    if (
      (isEmpty && estimatedHeight >= 5 && estimatedHeight <= 6) ||
      (!isEmpty && estimatedHeight === 5)
    ) {
      fiveLines = true;
    }

    htmlContent.push(
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  }

  if (
    ((isEmpty && estimatedHeight > 4) || (!isEmpty && estimatedHeight > 5)) &&
    estimatedHeight <= 6
  ) {
    fiveLines = true;
  }

  if (estimatedHeight <= (fiveLines ? 5 : 3)) {
    showSeeMore = false;
  }

  return (
    <div id="ember90" className={'occludable-update ember-view ' + className || ''} style={style}>
      <div
        id="ember201"
        data-urn="urn:li:activity:6886579114061426688"
        className="feed-shared-update-v2 feed-shared-update-v2--minimal-padding full-height relative artdeco-card ember-view"
      >
        <div>
          <h2 className="visually-hidden">Feed post</h2>
          <div
            className="feed-shared-actor display-flex
    feed-shared-actor--with-control-menu
    
    
    "
          >
            <a
              className="app-aware-link feed-shared-actor__container-link relative display-flex flex-grow-1"
              aria-label="View John Smith’s profile"
              target="_self"
              data-control-id="BeqqI1XVIJdGVPpk+u0vzw=="
              data-control-name="actor_container"
              href="#"
            >
              <div className="feed-shared-actor__container absolute" />
              <div
                className="feed-shared-actor__image relative"
                data-control-id="BeqqI1XVIJdGVPpk+u0vzw=="
                data-control-name="actor_picture"
              >
                <span
                  className="js-feed-shared-actor__avatar"
                  data-entity-hovercard-id="urn:li:fs_miniProfile:ACoAAAxjizUB2AsB36Jikyq_ZPEypefdfhV5H04"
                >
                  <div className="ivm-image-view-model    feed-shared-actor__avatar">
                    <div
                      className="ivm-view-attr__img-wrapper ivm-view-attr__img-wrapper--use-img-tag display-flex
    
    "
                    >
                      <div className="presence-entity presence-entity--size-3">
                        <img
                          src="https://media-exp1.licdn.com/dms/image/D4E03AQHiatStEyOmeA/profile-displayphoto-shrink_100_100/0/1636986789389?e=1647475200&v=beta&t=4WfmbDLJFATHNMnTtkGzrDTWgvpeqD-s5PE_VBamKWQ"
                          loading="lazy"
                          alt="John Smith"
                          id="ember202"
                          className="presence-entity__image  ivm-view-attr__img--centered EntityPhoto-circle-3 feed-shared-actor__avatar-image EntityPhoto-circle-3 lazy-image ember-view"
                        />
                        <div
                          className="presence-entity__indicator

presence-entity__indicator--size-3 presence-indicator
    presence-indicator--is-online
    presence-indicator--size-3"
                        >
                          <span className="visually-hidden">Status is online</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div
                className="feed-shared-actor__meta relative"
                data-control-id="BeqqI1XVIJdGVPpk+u0vzw=="
                data-control-name="actor"
              >
                <span className="feed-shared-actor__title">
                  <span
                    className="feed-shared-actor__name t-14 t-bold hoverable-link-text
      t-black"
                    data-entity-hovercard-id="urn:li:fs_miniProfile:ACoAAAxjizUB2AsB36Jikyq_ZPEypefdfhV5H04"
                  >
                    <span dir="ltr">John Smith</span>
                  </span>{' '}
                </span>
                <span
                  className="feed-shared-actor__description t-12 t-normal
      t-black--light"
                >
                  Fancy Pants Title at Company LTD
                </span>
                <span
                  className="feed-shared-actor__sub-description t-12 t-normal
      t-black--light"
                >
                  <span>
                    <span aria-hidden="true">
                      6h • Edited •{' '}
                      <LiIcon
                        aria-hidden="true"
                        type="globe-icon"
                        className="v-align-bottom"
                        size="small"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          data-supported-dps="16x16"
                          fill="currentColor"
                          className="mercado-match"
                          width={16}
                          height={16}
                          focusable="false"
                        >
                          <path d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z" />
                        </svg>
                      </LiIcon>{' '}
                    </span>
                    <span className="visually-hidden">2 hours ago</span>
                  </span>
                </span>
              </div>
            </a>
            <button
              className="entity-hovercard__a11y-trigger"
              aria-expanded="false"
              aria-label="See more about John Smith"
              data-entity-hovercard-id="urn:li:fs_miniProfile:ACoAAAxjizUB2AsB36Jikyq_ZPEypefdfhV5H04"
              data-entity-hovercard-trigger="click"
              type="button"
            />
          </div>
          <div className="feed-shared-control-menu feed-shared-update-v2__control-menu absolute text-align-right">
            <div
              id="ember204"
              className="artdeco-dropdown artdeco-dropdown--placement-bottom artdeco-dropdown--justification-right ember-view"
            >
              <button
                aria-expanded="false"
                tabIndex={0}
                id="ember205"
                className="feed-shared-control-menu__trigger artdeco-button artdeco-button--tertiary artdeco-button--muted artdeco-button--1 artdeco-button--circle artdeco-dropdown__trigger artdeco-dropdown__trigger--placement-bottom ember-view"
                type="button"
              >
                <LiIcon
                  type="ellipsis-horizontal-icon"
                  className="artdeco-button__icon"
                  role="img"
                  aria-label="Open control menu"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    data-supported-dps="24x24"
                    fill="currentColor"
                    className="mercado-match"
                    width={24}
                    height={24}
                    focusable="false"
                  >
                    <path d="M14 12a2 2 0 11-2-2 2 2 0 012 2zM4 10a2 2 0 102 2 2 2 0 00-2-2zm16 0a2 2 0 102 2 2 2 0 00-2-2z" />
                  </svg>
                </LiIcon>
              </button>
              <div
                tabIndex={-1}
                aria-hidden="true"
                id="ember206"
                className="feed-shared-control-menu__content artdeco-dropdown__content artdeco-dropdown--is-dropdown-element artdeco-dropdown__content--has-arrow artdeco-dropdown__content--arrow-right artdeco-dropdown__content--justification-right artdeco-dropdown__content--placement-bottom ember-view"
              />
            </div>
          </div>{' '}
          <div className="feed-shared-update-v2__description-wrapper" tabIndex={0}>
            <div
              className={`feed-shared-inline-show-more-text
                feed-shared-update-v2__description feed-shared-inline-show-more-text--minimal-padding
                ${fiveLines ? 'feed-shared-inline-show-more-text--5-lines' : ''} ${
                expanded ? 'feed-shared-inline-show-more-text--expanded' : ''
              }`}
              tabIndex={-1}
              style={expanded ? { maxHeight: 'none', display: 'block' } : {}}
            >
              <div
                className="feed-shared-text relative feed-shared-update-v2__commentary "
                dir="ltr"
              >
                <span className="break-words">
                  <span>
                    <span dir="ltr">{htmlContent}</span>
                  </span>
                </span>
              </div>
              {showSeeMore && (
                <button
                  className="feed-shared-inline-show-more-text__see-more-less-toggle see-more t-14 t-black--light t-normal hoverable-link-text"
                  aria-label="see more, visually reveals content which is already detected by screen readers"
                  type="button"
                  onClick={() => setExpanded(!expanded)}
                >
                  …see {expanded ? 'less' : 'more'}
                </button>
              )}
            </div>
          </div>
          <div id="ember207" className="social-details-social-activity update-v2-social-activity">
            {' '}
            <ul
              className="social-details-social-counts
    
    
    
    social-details-social-counts--with-social-proof"
            >
              <li className="social-details-social-counts__reactions social-details-social-counts__item social-details-social-counts__reactions--with-social-proof">
                <button
                  className="social-details-social-counts__count-value t-12 t-black--light t-normal hoverable-link-text display-flex "
                  aria-label="Faithful Follower reacted to John Smith’s post"
                  type="button"
                >
                  <img
                    className="reactions-icon social-detail-social-counts__count-icon social-detail-social-counts__count-icon--0 reactions-icon__consumption--small data-test-reactions-icon-type-LIKE data-test-reactions-icon-theme-light"
                    src="https://static-exp1.licdn.com/sc/h/8ekq8gho1ruaf8i7f86vd1ftt"
                    alt="like"
                    data-test-reactions-icon-type="LIKE"
                    data-test-reactions-icon-theme="light"
                  />
                  <img
                    className="reactions-icon social-detail-social-counts__count-icon social-detail-social-counts__count-icon--1 reactions-icon__consumption--small reactions-icon--stacked data-test-reactions-icon-type-APPRECIATION data-test-reactions-icon-theme-light"
                    src="https://static-exp1.licdn.com/sc/h/3wqhxqtk2l554o70ur3kessf1"
                    alt="support"
                    data-test-reactions-icon-type="APPRECIATION"
                    data-test-reactions-icon-theme="light"
                  />
                  <img
                    className="reactions-icon social-detail-social-counts__count-icon social-detail-social-counts__count-icon--2 reactions-icon__consumption--small reactions-icon--stacked data-test-reactions-icon-type-PRAISE data-test-reactions-icon-theme-light"
                    src="https://static-exp1.licdn.com/sc/h/b1dl5jk88euc7e9ri50xy5qo8"
                    alt="celebrate"
                    data-test-reactions-icon-type="PRAISE"
                    data-test-reactions-icon-theme="light"
                  />
                  <span className="social-details-social-counts__social-proof-container">
                    <span
                      aria-hidden="true"
                      className="social-details-social-counts__social-proof-fallback-number"
                    >
                      45
                    </span>
                    <span className="social-details-social-counts__social-proof-text">
                      Faithful Follower and 123 others
                    </span>
                  </span>
                </button>
              </li>
              <li className="social-details-social-counts__comments social-details-social-counts__item social-details-social-counts__item--with-social-proof">
                <button
                  className="social-details-social-counts__count-value t-12 t-black--light t-normal hoverable-link-text "
                  aria-label="10 comments on John Smith’s post"
                  type="button"
                >
                  <span
                    aria-hidden="true"
                    className="social-details-social-counts__item-text--with-social-proof"
                  >
                    10 comments
                  </span>
                </button>
              </li>
            </ul>
            <div>
              <div id="ember208" className="ember-view" />
            </div>
            <div
              className="feed-shared-social-actions feed-shared-social-action-bar social-detail-base-social-actions
    feed-shared-social-action-bar--full-width
    feed-shared-social-action-bar--has-social-counts"
            >
              <span className="reactions-react-button feed-shared-social-action-bar__action-button">
                <button
                  aria-label="Like John Smith’s post"
                  aria-pressed="false"
                  id="ember209"
                  className="artdeco-button artdeco-button--muted artdeco-button--4 artdeco-button--tertiary ember-view social-actions-button react-button__trigger
"
                >
                  <span className="artdeco-button__text">
                    <div
                      className="flex-wrap justify-center
  artdeco-button__text align-items-center"
                    >
                      <LiIcon aria-hidden="true" type="like-icon" className="artdeco-button__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          data-supported-dps="24x24"
                          fill="currentColor"
                          className="mercado-match"
                          width={24}
                          height={24}
                          focusable="false"
                        >
                          <path d="M19.46 11l-3.91-3.91a7 7 0 01-1.69-2.74l-.49-1.47A2.76 2.76 0 0010.76 1 2.75 2.75 0 008 3.74v1.12a9.19 9.19 0 00.46 2.85L8.89 9H4.12A2.12 2.12 0 002 11.12a2.16 2.16 0 00.92 1.76A2.11 2.11 0 002 14.62a2.14 2.14 0 001.28 2 2 2 0 00-.28 1 2.12 2.12 0 002 2.12v.14A2.12 2.12 0 007.12 22h7.49a8.08 8.08 0 003.58-.84l.31-.16H21V11zM19 19h-1l-.73.37a6.14 6.14 0 01-2.69.63H7.72a1 1 0 01-1-.72l-.25-.87-.85-.41A1 1 0 015 17l.17-1-.76-.74A1 1 0 014.27 14l.66-1.09-.73-1.1a.49.49 0 01.08-.7.48.48 0 01.34-.11h7.05l-1.31-3.92A7 7 0 0110 4.86V3.75a.77.77 0 01.75-.75.75.75 0 01.71.51L12 5a9 9 0 002.13 3.5l4.5 4.5H19z" />
                        </svg>
                      </LiIcon>
                      <span
                        aria-hidden="true"
                        className="artdeco-button__text react-button__text
      "
                      >
                        Like
                      </span>
                    </div>
                  </span>
                </button>
                <button
                  aria-label="Open reactions menu"
                  id="ember210"
                  className="artdeco-button artdeco-button--muted artdeco-button--2 artdeco-button--tertiary ember-view reactions-menu__trigger"
                >
                  <span className="artdeco-button__text">
                    <LiIcon
                      aria-hidden="true"
                      type="caret-filled-down-icon"
                      className="caret-filled-down-icon"
                      size="small"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        data-supported-dps="16x16"
                        fill="currentColor"
                        className="mercado-match"
                        width={16}
                        height={16}
                        focusable="false"
                      >
                        <path d="M8 11L3 6h10z" fillRule="evenodd" />
                      </svg>
                    </LiIcon>
                  </span>
                </button>
              </span>
              <span className="comment feed-shared-social-action-bar__action-button">
                <span
                  tabIndex={-1}
                  id="ember211"
                  className="artdeco-hoverable-trigger artdeco-hoverable-trigger--content-placed-top artdeco-hoverable-trigger--is-hoverable ember-view"
                >
                  <div>
                    <button
                      aria-describedby="artdeco-hoverable-artdeco-gen-54"
                      aria-label="Comment on John Smith’s post"
                      id="ember212"
                      className="artdeco-button artdeco-button--muted artdeco-button--4 artdeco-button--tertiary ember-view social-actions-button comment-button flex-wrap "
                    >
                      {' '}
                      <LiIcon
                        aria-hidden="true"
                        type="speech-bubble-icon"
                        className="artdeco-button__icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          data-supported-dps="24x24"
                          fill="currentColor"
                          className="mercado-match"
                          width={24}
                          height={24}
                          focusable="false"
                        >
                          <path d="M7 9h10v1H7zm0 4h7v-1H7zm16-2a6.78 6.78 0 01-2.84 5.61L12 22v-4H8A7 7 0 018 4h8a7 7 0 017 7zm-2 0a5 5 0 00-5-5H8a5 5 0 000 10h6v2.28L19 15a4.79 4.79 0 002-4z" />
                        </svg>
                      </LiIcon>
                      <span className="artdeco-button__text">Comment</span>
                    </button>
                  </div>
                  <div id="artdeco-gen-54" className="ember-view">
                    <div id="ember214" className="ember-view" />
                  </div>
                </span>
              </span>
              <span className="share-reshare-button  feed-shared-social-action-bar__action-button">
                <span
                  tabIndex={-1}
                  id="ember215"
                  className="artdeco-hoverable-trigger artdeco-hoverable-trigger--content-placed-top artdeco-hoverable-trigger--is-hoverable ember-view"
                >
                  <div>
                    <button
                      aria-describedby="artdeco-hoverable-artdeco-gen-55"
                      id="ember216"
                      className="artdeco-button artdeco-button--muted artdeco-button--4 artdeco-button--tertiary ember-view social-actions-button reshare-button flex-wrap "
                    >
                      {' '}
                      <LiIcon
                        aria-hidden="true"
                        type="share-linkedin-icon"
                        className="artdeco-button__icon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          data-supported-dps="24x24"
                          fill="currentColor"
                          className="mercado-match"
                          width={24}
                          height={24}
                          focusable="false"
                        >
                          <path d="M23 12l-4.61 7H16l4-6H8a3.92 3.92 0 00-4 3.84V17a4 4 0 00.19 1.24L5.12 21H3l-.73-2.22A6.4 6.4 0 012 16.94 6 6 0 018 11h12l-4-6h2.39z" />
                        </svg>
                      </LiIcon>
                      <span className="artdeco-button__text">Share</span>
                    </button>
                  </div>
                  <div id="artdeco-gen-55" className="ember-view">
                    <div id="ember218" className="ember-view" />
                  </div>
                </span>
                <div />
              </span>
              <div className="feed-shared-social-action-bar__action-button">
                <span
                  tabIndex={-1}
                  id="ember219"
                  className="artdeco-hoverable-trigger artdeco-hoverable-trigger--content-placed-top artdeco-hoverable-trigger--is-hoverable ember-view"
                >
                  <div
                    aria-describedby="artdeco-hoverable-artdeco-gen-56"
                    className="send-privately-button"
                  >
                    <button
                      className="message-anywhere-button social-actions-button send-privately-button artdeco-button artdeco-button--4 artdeco-button--tertiary flex-wrap  artdeco-button--muted "
                      aria-label="Send in a private message"
                      type="button"
                    >
                      <LiIcon aria-hidden="true" type="send-privately-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          data-supported-dps="24x24"
                          fill="currentColor"
                          className="mercado-match"
                          width={24}
                          height={24}
                          focusable="false"
                        >
                          <path d="M21 3L0 10l7.66 4.26L16 8l-6.26 8.34L14 24l7-21z" />
                        </svg>
                      </LiIcon>
                      <span className="artdeco-button__text">Send</span>
                    </button>
                  </div>
                  <div id="artdeco-gen-56" className="ember-view">
                    <div id="ember221" className="ember-view" />
                  </div>
                </span>
              </div>
            </div>
            <div className="content-analytics-entry-point">
              <a href="#" id="ember222" className="analytics-entry-point ember-view" role="button">
                <span className="icon-and-text-container t-14 t-black--light t-normal">
                  <LiIcon aria-hidden="true" type="analytics-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-supported-dps="24x24"
                      fill="currentColor"
                      className="mercado-match"
                      width={24}
                      height={24}
                      focusable="false"
                    >
                      <path d="M23 20v1H1v-1zM8 9H2v10h6zm7-6H9v16h6zm7 11h-6v5h6z" />
                    </svg>
                  </LiIcon>
                  <span>
                    <strong>1,234 views</strong> of your post in the feed
                  </span>
                </span>
              </a>
            </div>{' '}
            <div
              className="feed-shared-update-v2__comments-container display-flex flex-column
    "
            >
              {' '}
            </div>
          </div>
        </div>
      </div>

      <div style={{ paddingLeft: '20px' }}>
        <small>
          Five line mode: <strong>{fiveLines ? 'YES' : 'NO'}</strong> <br />
          Estimated lines: <strong>{estimatedHeight}</strong> <br />
          {Object.keys(additionalInfo || {}).map(key => (
            <React.Fragment key={key}>
              {key}: <strong>{additionalInfo[key]}</strong>
              <br />
            </React.Fragment>
          ))}
        </small>
      </div>
    </div>
  );
};
