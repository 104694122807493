import './App.css';

import React, { useMemo, useState } from 'react';

import { LinkedInEditor } from './LinkedInEditor';
import { PostPreview } from './PostPreview';
import { getStoredValue, storeValue } from './storage';

interface ISize {
  title: string;
  min: number;
}
const SIZES: ISize[] = [
  { title: 'Desktop', min: 540 },
  { title: 'Tablet', min: 493 },
  { title: 'Mobile', min: 432 },
];

function App() {
  const [value, setValue] = useState(getStoredValue('content', ''));

  const [sizeIndex, setSizeIndex] = useState<number>(getStoredValue('size', 0) || 0);

  const previewStyle = useMemo(() => {
    const size = SIZES[sizeIndex];
    return size.min ? { width: size.min + 'px' } : {};
  }, [sizeIndex]);

  return (
    <div className="App">
      <div className={'App-header'}>
        <h1>LinkedIn Writer</h1>
        <div className={'App-size'}>
          <label>Size:</label>
          {SIZES.map((size, index) => (
            <button
              key={index}
              className={sizeIndex === index ? 'selected' : null}
              onClick={() => {
                setSizeIndex(index);
                storeValue('size', index);
              }}
            >
              {size.title}
            </button>
          ))}
        </div>
      </div>
      <div className={'App-content'}>
        <div className={'App-preview'} style={previewStyle}>
          <PostPreview
            content={value}
            additionalInfo={{
              Size: `${SIZES[sizeIndex].min}px`,
            }}
          />
        </div>
        <LinkedInEditor
          className={'App-editor'}
          value={value}
          onChange={newValue => {
            setValue(newValue);
            storeValue('content', newValue);
          }}
        />
      </div>
    </div>
  );
}

export default App;
