import 'emoji-mart/css/emoji-mart.css';

import './LinkedInEditor.css';

import { Picker } from 'emoji-mart';
import React, { PropsWithChildren, Ref, useCallback, useMemo, useRef, useState } from 'react';
import { BaseEditor, Descendant, Editor, createEditor } from 'slate';
import { HistoryEditor, withHistory } from 'slate-history';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useSlate,
  withReact,
} from 'slate-react';

const CLASS = 'LinkedInEditor';

const FONT_MAPS = {
  bold: [
    {
      plain: ['A', 'Z'],
      formatted: ['𝗔', '𝗭'],
    },
    {
      plain: ['a', 'z'],
      formatted: ['𝗮', '𝘇'],
    },
    {
      plain: ['0', '9'],
      formatted: ['𝟬', '𝟵'],
    },
  ],
  italic: [
    {
      plain: ['A', 'Z'],
      formatted: ['𝘈', '𝘡'],
    },
    {
      plain: ['a', 'z'],
      formatted: ['𝘢', '𝘻'],
    },
  ],
  boldItalic: [
    {
      plain: ['A', 'Z'],
      formatted: ['𝘼', '𝙕'],
    },
    {
      plain: ['a', 'z'],
      formatted: ['𝙖', '𝙯'],
    },
    {
      plain: ['0', '9'],
      formatted: ['𝟬', '𝟵'],
    },
  ],
  monospace: [
    {
      plain: ['A', 'Z'],
      formatted: ['𝙰', '𝚉'],
    },
    {
      plain: ['a', 'z'],
      formatted: ['𝚊', '𝚣'],
    },
    {
      plain: ['0', '9'],
      formatted: ['𝟶', '𝟿'],
    },
  ],
};
const FONT_LIST = Object.keys(FONT_MAPS) as IFont[];
type IFont = keyof typeof FONT_MAPS;

function formattedToPlain(char: number): [char: number, font: IFont] {
  let resultChar: number = null;
  let resultFont: IFont = null;
  if (char <= 255) {
    // A slight optimization, since we know all ASCII are plain
    resultChar = char;
  } else {
    for (const font of FONT_LIST) {
      const fontMap = FONT_MAPS[font];
      for (const conversion of fontMap) {
        if (
          char >= conversion.formatted[0].codePointAt(0) &&
          char <= conversion.formatted[1].codePointAt(0)
        ) {
          const offset = char - conversion.formatted[0].codePointAt(0);
          resultChar = conversion.plain[0].codePointAt(0) + offset;
          resultFont = font;
          break;
        }
      }
    }
  }

  return [resultChar, resultFont];
}

function plainToFormatted(char: number, font: IFont) {
  const fontMap = FONT_MAPS[font];
  for (const conversion of fontMap) {
    if (
      char >= conversion.formatted[0].codePointAt(0) &&
      char <= conversion.formatted[1].codePointAt(0)
    ) {
      // Already formatted
      return char;
    }

    if (char >= conversion.plain[0].codePointAt(0) && char <= conversion.plain[1].codePointAt(0)) {
      // It's in the range this one supports. Convert.
      const offset = char - conversion.plain[0].codePointAt(0);
      return conversion.formatted[0].codePointAt(0) + offset;
    }
  }

  // Can't find a conversion.
  return null;
}

type ITargetFont = IFont | ((existingFont: IFont, plainChar: number, char: number) => IFont);

/**
 * Replace all characters with the given font character. Provide a function to make font decision per character.
 */
function applyFont(text: string, font: ITargetFont) {
  const resultCharCodes: number[] = [];
  for (const utf8Char of Array.from(text)) {
    const char = utf8Char.codePointAt(0);
    const [plainChar, existingFont] = formattedToPlain(char);
    if (!plainChar) {
      // We can't convert this character. Keep it as-is.
      resultCharCodes.push(char);
      continue;
    }
    const targetFont = typeof font === 'function' ? font(existingFont, plainChar, char) : font;
    const formattedChar = targetFont && plainToFormatted(plainChar, targetFont);
    resultCharCodes.push(formattedChar || plainChar);
  }
  return String.fromCodePoint(...resultCharCodes);
}

/**
 * Determine which font is active on given section. If multiple are present, returns all.
 * If there are plaintext convertable character, the section is considered plaintext.
 */
function getActiveFontSet(text: string): Set<IFont> {
  const resultSet = new Set<IFont>();
  for (const utf8Char of Array.from(text)) {
    const char = utf8Char.codePointAt(0);

    const [plainChar, detectedFont] = formattedToPlain(char);
    if (!plainChar) {
      // We can't convert this character to plain. So it's not ascii and our fonts don't know it.
      // A foreign character? Emoji? Whatever it may be, it doesn't affect us.
      continue;
    }

    if (!detectedFont) {
      // This is a plain character. But is it convertable to a font?
      for (const font of FONT_LIST) {
        const formatted = plainToFormatted(plainChar, font);
        if (formatted && formatted !== char) {
          // This char can be converted to a font. So we can assume font is not set.
          return null;
        }
      }
      // Character is not convertable to a font. Eg. " ". We can ignore this character and proceed.
      continue;
    }
    resultSet.add(detectedFont);
  }
  return resultSet;
}

// *********************************************************************************************************************

interface IElement {
  children: IText[];
}
interface IText {
  text: string;
}
interface IRenderLeafProps extends RenderLeafProps {
  leaf: IText;
}

type IEditor = BaseEditor & ReactEditor & HistoryEditor;

declare module 'slate' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface CustomTypes {
    Editor: IEditor;
    Element: IElement;
    Text: IText;
  }
}

// *********************************************************************************************************************

const ICONS = {
  bold: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z" />
    </svg>
  ),
  italic: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" />
    </svg>
  ),
  code: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M23 12l-7.071 7.071-1.414-1.414L20.172 12l-5.657-5.657 1.414-1.414L23 12zM3.828 12l5.657 5.657-1.414 1.414L1 12l7.071-7.071 1.414 1.414L3.828 12z" />
    </svg>
  ),
  emoji: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-5-7h2a3 3 0 0 0 6 0h2a5 5 0 0 1-10 0zm1-2a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm8 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
    </svg>
  ),
} as const;
type IIcon = keyof typeof ICONS;

const Icon: React.FC<{ icon: IIcon } & React.HTMLAttributes<HTMLSpanElement>> = ({
  icon,
  className,
  children,
  ...passProps
}) => (
  <span className={`${CLASS}-Icon ${className || ''}`} {...passProps}>
    {ICONS[icon]}
    {children}
  </span>
);

const Button = React.forwardRef(
  (
    {
      className,
      active,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
      } & React.HTMLAttributes<HTMLButtonElement>
    >,
    ref: Ref<HTMLButtonElement>
  ) => (
    <button
      type={'button'}
      {...props}
      ref={ref}
      className={`${CLASS}-Button ${className || ''} ${active ? `active` : ''}`}
    />
  )
);

function setFontForSelection(editor: IEditor, font: ITargetFont) {
  const selectedText = editor.selection ? Editor.string(editor, editor.selection) : '';
  if (selectedText) {
    Editor.insertText(editor, applyFont(selectedText, font));
  }
}

const ToolbarButton: React.FC<{
  active: boolean;
  icon: IIcon;
  onClick: () => void;
}> = ({ active, icon, onClick }) => {
  return (
    <Button active={active} onClick={() => onClick()}>
      <Icon icon={icon} />
    </Button>
  );
};

const Element: React.FC<RenderElementProps> = ({ attributes, children }) => {
  return <p {...attributes}>{children}</p>;
};
const Leaf: React.FC<IRenderLeafProps> = ({ attributes, text, leaf, children }) => (
  <span {...attributes}>{children}</span>
);

function isFontOrCombo(set: Set<IFont>, mainFont: IFont, comboFont: IFont) {
  if (!set) {
    return false;
  }
  if (set.size === 1) {
    return set.has(mainFont) || set.has(comboFont);
  }
  if (set.size === 2) {
    return set.has(mainFont) && set.has(comboFont);
  }
  return false;
}

const EditorContent: React.FC<{}> = () => {
  const editor = useSlate();
  const [pickingEmoji, doSetPickingEmoji] = useState(false);

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  const selectedText = editor.selection ? Editor.string(editor, editor.selection) : '';
  const activeFontSet = getActiveFontSet(selectedText);

  const isBoldActive = isFontOrCombo(activeFontSet, 'bold', 'boldItalic');
  const isItalicActive = isFontOrCombo(activeFontSet, 'italic', 'boldItalic');
  const isMonospaceActive =
    activeFontSet && activeFontSet.size === 1 && activeFontSet.has('monospace');

  const emojiRef = useRef<HTMLDivElement>();

  return (
    <div className={`${CLASS}-Content`}>
      <div className={`${CLASS}-bar`}>
        <ToolbarButton icon={'bold'} active={isBoldActive} onClick={toggleBold} />
        <ToolbarButton icon={'italic'} active={isItalicActive} onClick={toggleItalic} />
        <ToolbarButton icon={'code'} active={isMonospaceActive} onClick={toggleMonospace} />
        <ToolbarButton
          icon={'emoji'}
          active={pickingEmoji}
          onClick={() => setPickingEmoji(!pickingEmoji)}
        />
        <div
          className={`${CLASS}-emoji ${pickingEmoji ? 'visible' : 'hidden'}`}
          ref={emojiRef}
          onBlur={e => {
            if (!pickingEmoji) {
              return;
            }
            const me = e.currentTarget;
            let el = e.relatedTarget;
            do {
              if (el === me) {
                // We are good
                return;
              }
              el = el?.parentElement;
            } while (el);

            // El is not inside this div. Keep focus within.
            emojiRef.current?.querySelector('input')?.focus();
          }}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              setPickingEmoji(false);
            }
          }}
        >
          <Picker
            title="Pick an emoji"
            emoji={'point_up'}
            native={true}
            autoFocus={true}
            onSelect={emoji => {
              if (emoji) {
                Editor.insertText(editor, emoji['native']);
              }
              setPickingEmoji(false);
            }}
          />
        </div>
      </div>
      <Editable
        className={`${CLASS}-editor`}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck
        autoFocus
        onKeyDown={event => {
          if (event.ctrlKey || event.metaKey) {
            if (event.key === 'b') {
              toggleBold();
              event.preventDefault();
            } else if (event.key === 'i') {
              toggleItalic();
              event.preventDefault();
            } else if (event.key === '`') {
              toggleMonospace();
              event.preventDefault();
            } else if (event.key === ' ') {
              setPickingEmoji(true);
              event.preventDefault();
            }
          }
        }}
      />
    </div>
  );

  function toggleBold() {
    setFontForSelection(
      editor,
      isBoldActive
        ? font => (font === 'boldItalic' ? 'italic' : null)
        : font => (font === 'italic' ? 'boldItalic' : 'bold')
    );
  }

  function toggleItalic() {
    setFontForSelection(
      editor,
      isItalicActive
        ? font => (font === 'boldItalic' ? 'bold' : null)
        : font => (font === 'bold' ? 'boldItalic' : 'italic')
    );
  }

  function toggleMonospace() {
    setFontForSelection(editor, isMonospaceActive ? null : 'monospace');
  }

  function setPickingEmoji(targetState: boolean) {
    if (targetState) {
      doSetPickingEmoji(true);
      ReactEditor.blur(editor);
      setTimeout(() => {
        emojiRef.current?.querySelector('input')?.focus();
      }, 100);
    } else {
      doSetPickingEmoji(false);
      setTimeout(() => {
        ReactEditor.focus(editor);
      }, 100);
    }
  }
};

function stringToDescendants(value: string): Descendant[] {
  const lines = (value || '').split('\n');
  return lines.map(line => ({
    children: [
      {
        text: line,
      },
    ],
  }));
}

function descendantsToString(descendants: Descendant[]): string {
  return descendants
    .map(descendant => {
      if ('children' in descendant) {
        return descendant.children.map(child => child.text).join('');
      }
      return descendant.text;
    })
    .join('\n');
}

export const LinkedInEditor: React.FC<{
  className?: string;
  value?: string;
  onChange?: (newValue: string) => void;
}> = ({ className, value, onChange }) => {
  const editor = useMemo(() => withHistory(withReact(createEditor()) as any), []);

  return (
    <div className={`${CLASS} ${className || ''}`}>
      <Slate
        editor={editor}
        value={stringToDescendants(value)}
        onChange={descendants => onChange?.(descendantsToString(descendants))}
      >
        <EditorContent />
      </Slate>
      <small>
        Hint: <code>Ctrl+Space</code> to insert emoji. <code>Ctrl+B/I/`</code> for formatting. Cmd
        on Mac.
      </small>
    </div>
  );
};
