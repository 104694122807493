export function getStoredValue(key, fallback) {
  let value = localStorage.getItem(key);
  if (value !== undefined) {
    try {
      value = JSON.parse(value);
    } catch (_) {}
  }
  return value === undefined ? fallback : value;
}

export function storeValue(key, value) {
  if (value === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
